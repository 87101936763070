import React from "react";

import Layout from "../components/layout";

import Link from "gatsby-link";

import SEO from "../components/seo";

import BackgroundImage from "gatsby-background-image";
import BackToBlogLink from "./BackToBlogButton";
import BlogPageBannerWrapper from "./BlogPageBannerWrapper";
import BlogList from "./BlogList";

const BlogPostListPage = ({ tag, tagName, list, imageData }) => {
  const headingText = "All posts from the " + tag + ": ' " + tagName + " ' ";
  return (
    <Layout
      headerClass="bg-white relative"
      bodyClass="px-0 md:px-0 lg:px-0 min-h-screen"
    >
      <SEO title="Angela Cooney - Executive Coach - Blog" />
      <BlogPageBannerWrapper image={imageData}>
        <div className="container mx-auto px-6 mt-6 md:px-10 lg:px-16 pb-4 bg-purple-900 heropattern-texture-teal-900 flex flex-col items-center">
          <BackToBlogLink />
          <BlogList blogPosts={list} blogHeadingText={headingText} />
          <BackToBlogLink />
        </div>
      </BlogPageBannerWrapper>
    </Layout>
  );
};
export default BlogPostListPage;
