import React from "react";
import { graphql } from "gatsby";
import BlogPostListPage from "../components/blogPostListPage";

const Category = ({ data, pageContext }) => {
  const blogPosts = data.links.nodes;
  const imageData = data.banner.childImageSharp.fluid;

  return (
    <>
    <BlogPostListPage tag={pageContext.identifier} tagName={pageContext.name} list={blogPosts} imageData={imageData} />
    </>
  );
};

export const query = graphql`
  query CatPage($slug: String!) {
    links: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      totalCount
      nodes {
        id
        title
        excerpt
        slug
        date(formatString: "DD MMMM YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AVIF, WEBP, JPG]
                )
              }
            }
          }
        }
      }
    }
    banner: file(
      relativePath: { eq: "blog-executive-coach-angela-cooney-banner.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Category;
